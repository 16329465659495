import { GridContainer } from "@ritual/essentials-for-react";
import { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

// Redux
import cartProductSelectors from "../../store/cart-product/selectors";
import cartSelectors from "../../store/cart/selectors";
import couponSelectors from "../../store/coupon/selectors";
import promotionSelectors from "../../store/promotion/selectors";

import { fetchCart } from "../../store/cart/actions";

// Components
import CartLedger from "../../components/checkout/CartLedger";
import ErrorBanner from "../../components/checkout/ErrorBanner";
import SavingsBanner from "../../components/checkout/SavingsBanner";
import StripeElementsCheckout from "../../components/checkout/StripeElementsCheckout";
import SimpleFooter from "../../components/global/navigation/SimpleFooter";
import SimpleNav from "../../components/global/navigation/SimpleNav";
import GridColumn from "../../components/grid/GridColumn";
import GridRow from "../../components/grid/GridRow";
import SubscriptionBenefits from "../../components/product/hero/SubscriptionBenefits";
import useVariation from "../../hooks/useVariation";

// Util functions and types
import ElementsWrapper from "../../components/checkout/ElementsWrapper";
import { finalizePurchase, patchCart } from "../../components/checkout/utils";
import { clearStorage } from "../../components/checkout/utils/storage";
import useStripeReturnedIntentHandler, {
  StripePaymentErrorHandler,
  StripePaymentSuccessHandler,
} from "../../components/checkout/utils/useStripeReturnedIntentHandler";
import { navigate } from "../../services/navigation";
import Cart from "../../store/cart/model";
import metrics from "../../utils/metrics";
import {
  trackCartUpdated,
  trackCheckoutStarted,
  trackOrderCompleted,
} from "../../utils/tracking/cart";
import {
  AddressVerificationOutput,
  StripeFormattedAddress,
} from "../../utils/types/validateAddressTypes";
import { amountPriceTotal, basePriceTotal } from "../../utils/cart";
import * as Sentry from "@sentry/react";

const PageWrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
`;

const PageBackground = styled.div`
  background-color: var(--warm-20);
  flex: 1 1 100%;

  @media (min-width: 933px) {
    padding: var(--spacing-3) 0;
  }
`;

const LeftColumnSpacing = styled.div`
  margin-bottom: var(--spacing-3);

  @media (min-width: 933px) {
    max-width: 660px;
    margin-left: auto;
  }
`;

const RightColumnSpacing = styled.div`
  @media (min-width: 933px) {
    max-width: 432px;
  }
`;

const ValuePropsWrapperDesktop = styled.div`
  padding: 0 var(--spacing-1_5) var(--spacing-1_5);

  @media (min-width: 750px) {
    padding-top: var(--spacing-1_5);
  }

  & > * {
    margin-top: var(--spacing-1_5);
  }
`;

const ValuePropsWrapperMobile = styled.div`
  @media (min-width: 933px) {
    display: none;
  }

  & > * {
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-2);
  }
`;

const getOrderNumber = () => {
  const orderNumber = sessionStorage.getItem("rit-order_number");
  if (!orderNumber) {
    Sentry.captureException({ error: "rit-order_number not found" });
    return null;
  }
  return orderNumber;
};

const getPurchasedCart = () => {
  try {
    const cart = sessionStorage.getItem("rit-purchased_cart");
    if (!cart) {
      Sentry.captureException({ error: "rit-purchased_cart not found" });
      return {};
    }
    return JSON.parse(cart);
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
};

const getPurchasedCartProducts = () => {
  try {
    const products = sessionStorage.getItem("rit-purchased_cart_products");
    if (!products) {
      Sentry.captureException({
        error: "rit-purchased_cart_products not found",
      });
      return [];
    }
    return JSON.parse(products);
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

const getAppliedPromotion = () => {
  try {
    const promotion = sessionStorage.getItem("rit-applied_promotion");
    if (!promotion || promotion === "undefined") {
      return null;
    }
    return JSON.parse(promotion);
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};

const Checkout = () => {
  const [addressValidationResult, setAddressValidationResult] =
    useState<AddressVerificationOutput | null>(null);

  const [name, setName] = useState<string | null>(null);
  const [address, setAddress] = useState<StripeFormattedAddress | null>(null);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [lastPaymentError, setLastPaymentError] = useState<any>(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [tryingPurchase, setTryingPurchase] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const useGoogleMapsAddressValidation = useVariation(
    "use-google-maps-address-validation",
  );
  const welcomeOfferPromotionFlag = useVariation("welcome-offer-promotion", {});
  const welcomeOfferPromotionEnabled =
    welcomeOfferPromotionFlag?.slug &&
    welcomeOfferPromotionFlag?.slug === "welcome-offer-alc";

  const dispatch = useDispatch();

  const activeCart = useSelector(cartSelectors.activeCart);
  const activeCartProducts = useSelector(
    cartProductSelectors.sortedActiveCartProducts,
  );
  const hasEpre = useSelector(cartProductSelectors.activeCartContainsEpre);
  const isProcessing = useSelector(cartSelectors.isProcessing);
  const activeCoupon = useSelector(couponSelectors.activeCoupon);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);

  const recurringBillingDetails = activeCart?.recurringBillingDetails;

  const canCheckout =
    !isProcessing && activeCart && activeCartProducts && !tryingPurchase;

  useEffect(() => {
    setHasLoaded(true);

    trackCheckoutStarted("Checkout Page");
  }, []);

  const onPaymentSuccess =
    useCallback<StripePaymentSuccessHandler>(async () => {
      const cartId = sessionStorage.getItem("rit-cart_id");
      await finalizePurchase(cartId!);

      handleOrderCompleted({
        marketingPreference: sessionStorage.getItem("rit-marketing_preference"),
      });

      navigate("/confirmation");
      setIsConfirmed(true);
    }, []);

  const onPaymentError = useCallback<StripePaymentErrorHandler>(
    (paymentError) => {
      clearStorage();
      if ("last_payment_error" in paymentError) {
        setLastPaymentError(paymentError);
      }
      setShowErrorBanner(true);
      setTryingPurchase(false);
    },
    [],
  );

  const isIntentHandlerCompleted = useStripeReturnedIntentHandler(
    onPaymentSuccess,
    onPaymentError,
  );

  const handleOrderCompleted = (options?: any) => {
    const defaultOptions = {
      addMarketingPreference: true,
      ...options,
    };

    try {
      trackOrderCompleted(
        getOrderNumber(),
        getPurchasedCart(),
        getPurchasedCartProducts(),
        getAppliedPromotion(),
        defaultOptions,
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (
      isIntentHandlerCompleted &&
      !isConfirmed &&
      activeCart &&
      (activeCart.subtotal === 0 || !activeCart.subtotal) &&
      activeCartProducts.length < 1 &&
      !isProcessing
    ) {
      navigate("/");
    }
  }, [
    isIntentHandlerCompleted,
    isConfirmed,
    activeCart,
    activeCartProducts,
    isProcessing,
  ]);

  const handleAddressChange = async (
    address: StripeFormattedAddress,
    activeCart: Cart,
  ) => {
    if (
      !address?.line1 ||
      !address?.city ||
      !address?.state ||
      !address?.postal_code ||
      !address?.country
    ) {
      return;
    }

    setLastPaymentError(null);
    setShowErrorBanner(false);

    const payload: any = activeCart.serialize({
      shippingAddress: { ...address, name },
    });

    try {
      const response = await patchCart(payload.data.attributes, activeCart);
      await dispatch(fetchCart());
      setDisableSubmit(false);

      sessionStorage.setItem(
        "rit-purchased_cart",
        JSON.stringify(response.data.attributes),
      );

      const appliedPromotionId = response.data.relationships.promotion.data?.id;
      const appliedPromotion =
        appliedPromotionId &&
        response.included.find((i: any) => i.id === appliedPromotionId);

      sessionStorage.setItem(
        "rit-applied_promotion",
        JSON.stringify(appliedPromotion),
      );

      metrics.track("Shipping Info Entered");
      trackCartUpdated();
    } catch (error: any) {
      if (error.errors?.[0]?.source === "Taxjar") {
        setLastPaymentError({
          decline_code: "zip_code_not_matching_state",
        });
        setShowErrorBanner(true);
        setDisableSubmit(true);
      }
    }
  };

  let savings =
    (activeCart?.discountAmount || 0) +
    (activeCart?.productOfferDiscountAmount || 0);

  if (welcomeOfferPromotionEnabled) {
    const basePriceDiscount =
      basePriceTotal(activeCartProducts) - amountPriceTotal(activeCartProducts);
    savings = (activeCart?.productOfferDiscountAmount || 0) + basePriceDiscount;
  }

  return (
    <>
      <Helmet>
        <title>Checkout | Ritual</title>
      </Helmet>
      <PageWrapper>
        <SimpleNav />
        {savings > 0 && <SavingsBanner savingsAmount={savings} />}
        {showErrorBanner && <ErrorBanner lastPaymentError={lastPaymentError} />}

        <PageBackground>
          <GridContainer>
            <GridRow>
              <GridColumn
                xs={{ size: 6 }}
                s={{ size: 12 }}
                m={{ size: 12, hide: true }}
              >
                {hasLoaded && activeCart && activeCartProducts && (
                  <CartLedger
                    activeCart={activeCart}
                    activeCartProducts={activeCartProducts}
                    activeCoupon={activeCoupon}
                    appliedPromotion={appliedPromotion}
                    isProcessing={isProcessing}
                  />
                )}
              </GridColumn>

              <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 7 }}>
                <LeftColumnSpacing>
                  {hasLoaded && (
                    <StripeElementsCheckout
                      address={address}
                      name={name}
                      setName={setName}
                      addressValidationResult={addressValidationResult}
                      setAddressValidationResult={setAddressValidationResult}
                      useGoogleMapsAddressValidation={
                        useGoogleMapsAddressValidation
                      }
                      setShowErrorBanner={setShowErrorBanner}
                      setLastPaymentError={setLastPaymentError}
                      activeCart={activeCart}
                      activeCartProducts={activeCartProducts}
                      handleAddressChange={handleAddressChange}
                      setAddress={setAddress}
                      disableSubmit={disableSubmit}
                      recurringBillingDetails={recurringBillingDetails}
                      canCheckout={canCheckout}
                      tryingPurchase={tryingPurchase}
                      setTryingPurchase={setTryingPurchase}
                      handleOrderCompleted={handleOrderCompleted}
                    />
                  )}
                  <ValuePropsWrapperMobile>
                    <SubscriptionBenefits
                      product={activeCartProducts[0]}
                      showFsaHsa={hasEpre}
                    />
                  </ValuePropsWrapperMobile>
                </LeftColumnSpacing>
              </GridColumn>

              <GridColumn
                xs={{ size: 6, hide: true }}
                s={{ size: 6, hide: true }}
                m={{ size: 5 }}
              >
                <RightColumnSpacing>
                  {hasLoaded && activeCart && activeCartProducts && (
                    <>
                      <CartLedger
                        activeCart={activeCart}
                        activeCartProducts={activeCartProducts}
                        activeCoupon={activeCoupon}
                        appliedPromotion={appliedPromotion}
                        isProcessing={isProcessing}
                      />
                      <ValuePropsWrapperDesktop>
                        <SubscriptionBenefits
                          product={activeCartProducts[0]}
                          showFsaHsa={hasEpre}
                        />
                      </ValuePropsWrapperDesktop>
                    </>
                  )}
                </RightColumnSpacing>
              </GridColumn>
            </GridRow>
          </GridContainer>
        </PageBackground>
        <SimpleFooter />
      </PageWrapper>
    </>
  );
};

const CheckoutPage = () => (
  <ElementsWrapper>
    <Checkout />
  </ElementsWrapper>
);

export default CheckoutPage;
