import { variation } from "../services/launchDarkly";
import { getStore } from "../store/createStore";
import userSelectors from "../store/user/selectors";
import { getCookie } from "./cookies";
import { safeParse, IsStorageSupported } from "./helpers";

let fallbackAnonymousId = "";

const FallbackUser = {
  id: () => "",
  anonymousId: () => fallbackAnonymousId,
  traits: () => {
    return {};
  },
};

export const analytics = {
  on: (...args) =>
    callIfPresent(window.analytics && window.analytics.on, { args }),

  /**
   * Waits for window.analytics to be available and window.analytics.ready to
   * be called before returning the Segment user info. If window.analytics is
   * not available when this function is called, we continuously poll until
   * it becomes available.
   *
   * @return {Object} the userInfo object { id:, anonymousId:, traits: }
   */
  waitForUserInfo() {
    return waitForAnalyticsAndGetUser();
  },

  /**
   * Synchronously gets the user info from stored Segment data without waiting
   * for window.analytics or window.analytics.ready. Most of the time (unless
   * Cookies are cleared or it's a first time load), the stored Segment data
   * will be accurate. If the stored data is not available, we return a user
   * with a mock anonymousId.
   */
  getStoredUserInfo() {
    return getUserInfo();
  },

  setAnonymousId: (anonymousId) => {
    fallbackAnonymousId = anonymousId;
    callIfPresent(window.analytics && window.analytics.setAnonymousId, {
      args: [anonymousId],
    });
  },

  get anonymousId() {
    return getAnonymousId();
  },
};

export const fixFullstoryIntegration = ({ payload, next }) => {
  if (
    payload.obj.integrations &&
    payload.obj.integrations.hasOwnProperty("Fullstory (Actions)")
  ) {
    payload.obj.integrations.FullStory =
      payload.obj.integrations["Fullstory (Actions)"];
    delete payload.obj.integrations["Fullstory (Actions)"];
  }
  next(payload);
};

export const pushUserTraitsToDataLayer = ({ payload, next }) => {
  if (
    payload.obj.type === "identify" &&
    typeof window.dataLayer !== "undefined"
  ) {
    window.dataLayer.push(window.analytics.user().traits());
  }
  next(payload);
};

export const injectIPv6Context = ({ payload, next }) => {
  let ipv6 = sessionStorage.getItem("clientIPv6");

  if (ipv6) {
    payload.obj.context = {
      ...payload.obj.context,
      ipv6,
    };
  }
  next(payload);
};

export const injectIntercomUserHash = ({ payload, next }) => {
  // Only inject the Intercom user hash if the payload is an identify event
  if (payload.obj.type !== "identify") {
    return next(payload);
  }

  const state = getStore().getState();
  const user = userSelectors.activeUser(state);

  if (!user || !user.intercomHash) {
    return next(payload);
  }

  payload.obj.integrations = {
    ...payload.obj.integrations,
    Intercom: {
      ...(payload.obj.integrations?.Intercom || {}),
      user_hash: user.intercomHash,
    },
  };
  next(payload);
};

export const injectFacebookCookies = ({ payload, next }) => {
  if (!["track", "page"].includes(payload.obj.type)) return next(payload);

  const fbp = getCookie("_fbp");
  const fbc = getCookie("_fbc");

  if (!fbp && !fbc) return next(payload);

  const fbIntegrations = {
    ...(fbp && { fbp }),
    ...(fbc && { fbc }),
  };

  // TODO: Deprecate adding to properties in favor of integrations only in the future
  payload.obj.properties = {
    ...payload.obj.properties,
    ...fbIntegrations,
  };

  payload.obj.integrations = {
    ...payload.obj.integrations,
    "Facebook Conversions API (Actions)": {
      ...(payload.obj.integrations?.["Facebook Conversions API (Actions)"] ||
        {}),
      ...fbIntegrations,
    },
  };

  next(payload);
};

export const injectGoogleCookies = ({ payload, next }) => {
  if (!["track", "page"].includes(payload.obj.type)) return next(payload);

  const _gcl_aw = getCookie("_gcl_aw");
  const gclid = getCookie("gclid");

  if (_gcl_aw || gclid) {
    payload.obj.integrations = {
      ...payload.obj.integrations,
      "Google Enhanced Conversions": {
        ...(payload.obj.integrations?.["Google Enhanced Conversions"] || {}),
        ...(_gcl_aw && { _gcl_aw }),
        ...(gclid && { gclid }),
      },
    };
  }

  next(payload);
};

export const injectTikTokCookies = ({ payload, next }) => {
  if (!["track", "page"].includes(payload.obj.type)) return next(payload);

  const ttp = getCookie("_ttp");
  const ttclid = getCookie("ttclid");

  if (ttp || ttclid) {
    payload.obj.integrations = {
      ...payload.obj.integrations,
      "Tiktok Conversions": {
        ...(payload.obj.integrations?.["Tiktok Conversions"] || {}),
        ...(ttp && { ttp }),
        ...(ttclid && { ttclid }),
      },
    };
  }

  next(payload);
};

export const injectIterableCookies = ({ payload, next }) => {
  if (!["track", "page"].includes(payload.obj.type)) return next(payload);

  const iterableCampaignId = getCookie("iterableEmailCampaignId");
  const iterableTemplateId = getCookie("iterableTemplateId");
  const messageId = getCookie("iterableMessageId");

  if (iterableCampaignId || iterableTemplateId || messageId) {
    const campaignId = Number(iterableCampaignId);
    const templateId = Number(iterableTemplateId);

    payload.obj.integrations = {
      ...payload.obj.integrations,
      "Iterable (Actions)": {
        ...(payload.obj.integrations?.["Iterable (Actions)"] || {}),
        ...(campaignId && !isNaN(campaignId) && { campaignId }),
        ...(templateId && !isNaN(templateId) && { templateId }),
        ...(messageId && { messageId }),
      },
    };
  }

  next(payload);
};

export const injectRedditCookies = ({ payload, next }) => {
  if (!["track", "page"].includes(payload.obj.type)) return next(payload);

  const _rdt_uuid = getCookie("_rdt_uuid");

  if (_rdt_uuid) {
    payload.obj.integrations = {
      ...payload.obj.integrations,
      "Reddit Conversions Api": {
        ...(payload.obj.integrations?.["Reddit Conversions Api"] || {}),
        ...(_rdt_uuid && { _rdt_uuid }),
      },
    };
  }

  next(payload);
};

export const injectPinterestCookies = ({ payload, next }) => {
  if (!["track", "page"].includes(payload.obj.type)) return next(payload);

  const epik = getCookie("_epik");

  if (epik) {
    payload.obj.integrations = {
      ...payload.obj.integrations,
      "Pinterest Conversions API": {
        ...(payload.obj.integrations?.["Pinterest Conversions API"] || {}),
        ...(epik && { epik }),
      },
    };
  }

  next(payload);
};

/**
 * Handles FullStory sampling and initialization
 */
const setupFullStory = () => {
  const fullstorySampled = variation("fullstory-session-capture", false);
  const fsAvailable = window && window.FS;
  if (fullstorySampled && fsAvailable) {
    window.FS("start");
  }
};

/**
 * Registers analytics middleware and resolves when analytics is ready
 * @returns {Promise<void>}
 */
const setupAnalytics = async () => {
  await new Promise((resolve) => {
    window.analytics.addSourceMiddleware(fixFullstoryIntegration);
    window.analytics.addSourceMiddleware(pushUserTraitsToDataLayer);
    window.analytics.addSourceMiddleware(injectIPv6Context);
    window.analytics.addSourceMiddleware(injectIntercomUserHash);
    window.analytics.addSourceMiddleware(injectFacebookCookies);
    window.analytics.addSourceMiddleware(injectGoogleCookies);
    window.analytics.addSourceMiddleware(injectTikTokCookies);
    window.analytics.addSourceMiddleware(injectIterableCookies);
    window.analytics.addSourceMiddleware(injectRedditCookies);
    window.analytics.addSourceMiddleware(injectPinterestCookies);
    window.analytics.ready(() => {
      setupFullStory();
      resolve();
    });
  });
};

export const registerMiddleware = async () => {
  if (window.analytics && typeof window.analytics.ready === "function") {
    await setupAnalytics();
    return getUserInfo();
  }

  await new Promise((resolve) => setTimeout(resolve, 100));
  return waitForAnalyticsAndGetUser();
};

/**
 * Polls for windows.analytics and window.analytics.ready and resolves with
 * the Segment userInfo. Waiting for the ready function ensures that the
 * window.analytics.user function will be available, and that the userInfo
 * returned will be up to date.
 *
 * If window.analytics or window.analytics.ready never become available, then
 * this function will never resolve.
 *
 * @return {Promise<Object>} a promise that resolves to the userInfo
 */
const waitForAnalyticsAndGetUser = async () => {
  if (window.analytics && typeof window.analytics.ready === "function") {
    await setupAnalytics();
    return getUserInfo();
  }

  await new Promise((resolve) => setTimeout(resolve, 100));
  return waitForAnalyticsAndGetUser();
};

if (typeof window !== "undefined") {
  registerMiddleware();
}

function getUserInfo() {
  return {
    id: getUserId(),
    anonymousId: getAnonymousId(),
    traits: getUserTraits(),
  };
}

function getUser() {
  return callIfPresent(window.analytics && window.analytics.user, {
    defaultReturn: FallbackUser,
  });
}

/**
 * Retrieves Segment storage data from Cookies based on the specified
 * cookieKey. If the value is not available, the onNoValue function called
 * instead.
 *
 * @param {String} cookieKey
 * @param {Function} onNoValue the function to call if the value is not
 *  available in storage
 * @return {*} The parsed JSON value
 */
export function getCookieValue(cookieKey, onNoValue) {
  const cookieValue = getCookie(cookieKey);
  return cookieValue ? safeParse(cookieValue, cookieValue) : onNoValue();
}

/**
 * Retrieves Segment storage data from Local Storage based on the specified
 * storageKey. If the value is not available, the onNoValue function called
 * instead.
 *
 * @param {String} storageKey
 * @param {Function} onNoValue the function to call if the value is not
 *  available in storage
 * @return {*} The parsed JSON value
 */
export function getStorageValue(storageKey, onNoValue) {
  if (!IsStorageSupported) return onNoValue();

  const storeValue = window.localStorage.getItem(storageKey);
  return storeValue ? safeParse(storeValue, storeValue) : onNoValue();
}

function getUserId() {
  return getCookieValue("ajs_user_id", getUser().id);
}

function getAnonymousId() {
  return getCookieValue("ajs_anonymous_id", getUser().anonymousId);
}

function getUserTraits() {
  // The ajs_user_traits are only persisted in Local Storage.
  return getStorageValue("ajs_user_traits", getUser().traits);
}

/** Non-analytics related helper functions */

function callIfPresent(fn, options = {}) {
  const { args = [], defaultReturn } = options;
  const callee =
    typeof fn === "function"
      ? fn
      : () => {
          return defaultReturn;
        };
  return callee(...args);
}
