import metrics from "../metrics";
import { getStore } from "../../store/createStore";
import cartSelectors from "../../store/cart/selectors";
import userSelectors from "../../store/user/selectors";
import { identifyUser } from "../currentUser";
import SHA from "js-sha256";

export function trackSignUp(method, options) {
  const { marketingPreference } = options;
  const state = getStore().getState();
  const activeCart = cartSelectors.activeCart(state);
  const user = userSelectors.activeUser(state);

  const currency = activeCart?.currency
    ? activeCart.currency?.toUpperCase()
    : "USD";

  const value = activeCart?.preTaxTotal
    ? (activeCart.preTaxTotal / 100).toFixed(2)
    : "0.00";

  identifyUser();

  metrics.track(
    "User Signup",
    {
      method,
      currency,
      value,
      userHashedEmail: SHA.sha256(user.email),
      marketingPreference,
    },
    {
      addMarketingPreference: true,
      addTraitsToContext: true,
    },
  );
}

export function trackSignIn(method) {
  const state = getStore().getState();
  const user = userSelectors.activeUser(state);

  identifyUser();

  metrics.track("User Login", {
    method,
    userHashedEmail: SHA.sha256(user.email),
  });
}
